import { NodeEnvironment } from 'constants/names'
import { isMainHeadquarters } from 'website/utils/isMultirooftop'

/**
 * DD-NOTE:
 * We temporarily have this exclusively for ProjetOne/multirooftop. In future we may add support for all dealerships.
 * In the past we had some issues with UI freezing because of hotjar so now we turn it on only if needed.
 */

export const PROJECT_ONE = 'DriveSimple_Carlstadt'
const _isMainMultirooftop = isMainHeadquarters()

export const injectHotjar = (): void => {
  const appId = process.env.REACT_APP_CONFIG_ID ?? ''

  if (process.env.NODE_ENV !== NodeEnvironment.Production) {
    return
  }

  if (appId !== PROJECT_ONE && !_isMainMultirooftop) {
    console.info('HOTJAR_ID is not supported for Dealership')
    return
  }

  const HOTJAR_ID = _isMainMultirooftop
    ? process.env.REACT_APP_GLOBAL_SITE_HOTJAR_ID
    : process.env[`REACT_APP_${appId.toUpperCase()}_HOTJAR_ID`]

  if (HOTJAR_ID == null || HOTJAR_ID === '') {
    console.info('HOTJAR_ID is not set')
    return
  }

  const scriptCode = `
  (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:'${HOTJAR_ID}',hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `

  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.text = scriptCode

  document.head.appendChild(script)
}
